<template>
  <section class="paga404">
    <div class="container">
      <h1 class="mt-40 p">{{ t('errors.404') }}</h1>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Page404'
}
</script>